import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';

const ColdEmailCampaignForm = () => {
  const [formData, setFormData] = useState({
    product: '',
    features: '',
    problem: '',
    email: '',
    cta: 'Schedule a call',
    tone: 'Formal',
    jargon: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Here you would typically send the data to a server
  };

  return (
    <div className="bg-black min-h-screen text-white">
      <header className="p-4 flex justify-between items-center">
        <div className="flex items-center">
          <div className="text-white font-bold text-2xl mr-4">V</div>
          <nav>
            <ul className="flex space-x-4">
              <li>Products</li>
              <li>Pricing</li>
              <li>Resources</li>
              <li>Company</li>
            </ul>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          <button className="text-white">Log In</button>
          <button className="bg-orange-500 text-white px-4 py-2 rounded">Go to dashboard</button>
        </div>
      </header>

      <main className="container mx-auto mt-8 p-4">
        <h1 className="text-4xl font-bold mb-4">Cold Email Campaign</h1>
        <p className="mb-8">Let's set up the email message & find your future clients</p>

        <div className="flex justify-between items-center mb-8">
          <div className="flex items-center">
            <div className="w-8 h-8 rounded-full bg-green-500 flex items-center justify-center mr-2">✓</div>
            <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center mr-2">2</div>
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mr-2">3</div>
            <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center">4</div>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="bg-white text-gray-800 p-8 rounded-lg">
          <h2 className="text-2xl font-bold mb-4">Fill the questionnaire</h2>
          <p className="mb-6">Answer the questionnaire for message crafting</p>

          <div className="space-y-6">
            <div>
              <label className="block mb-2">1. What is the main product/service you are selling?*</label>
              <textarea 
                name="product"
                value={formData.product}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                rows="3"
              />
            </div>

            <div>
              <label className="block mb-2">2. What are the key features of your product/service?*</label>
              <textarea 
                name="features"
                value={formData.features}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                rows="3"
              />
            </div>

            <div>
              <label className="block mb-2">3. What problem does your product/service solve for the client?*</label>
              <textarea 
                name="problem"
                value={formData.problem}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                rows="3"
              />
            </div>

            <div>
              <label className="block mb-2">4. Please formulate your potential email in your own words:*</label>
              <textarea 
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                rows="3"
              />
            </div>

            <div>
              <label className="block mb-2">5. What is the call-to-action (CTA) you want to emphasize?*</label>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input 
                    type="radio" 
                    name="cta" 
                    value="Schedule a call" 
                    checked={formData.cta === 'Schedule a call'} 
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Schedule a call
                </label>
                <label className="flex items-center">
                  <input 
                    type="radio" 
                    name="cta" 
                    value="Visit website" 
                    checked={formData.cta === 'Visit website'} 
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Visit website
                </label>
              </div>
            </div>

            <div>
              <label className="block mb-2">6. What tone do you want to set?*</label>
              <div className="space-y-2">
                <label className="flex items-center">
                  <input 
                    type="radio" 
                    name="tone" 
                    value="Formal" 
                    checked={formData.tone === 'Formal'} 
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Formal
                </label>
                <label className="flex items-center">
                  <input 
                    type="radio" 
                    name="tone" 
                    value="Friendly" 
                    checked={formData.tone === 'Friendly'} 
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  Friendly
                </label>
              </div>
            </div>

            <div>
              <label className="block mb-2">7. Is there any industry jargon or terminology that should be included or avoided?</label>
              <textarea 
                name="jargon"
                value={formData.jargon}
                onChange={handleInputChange}
                className="w-full p-2 border rounded"
                rows="3"
              />
            </div>
          </div>

          <div className="mt-8 flex justify-between">
            <button type="button" className="px-4 py-2 border rounded">Previous page</button>
            <button type="submit" className="px-4 py-2 bg-orange-500 text-white rounded flex items-center">
              Next to confirmation
              <ChevronRight className="ml-2" />
            </button>
          </div>
        </form>
      </main>

      <footer className="bg-gray-100 text-gray-600 mt-16 py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-4 gap-8">
            <div>
              <h3 className="font-bold mb-2">VersioInfo</h3>
              <p>Customer prospecting and research service that helps businesses quickly and set appointments.</p>
            </div>
            <div>
              <h3 className="font-bold mb-2">PRODUCTS</h3>
              <ul>
                <li>Cold Email Campaign</li>
                <li>Database Service</li>
                <li>Pricing</li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-2">ABOUT US</h3>
              <ul>
                <li>Company</li>
                <li>Contacts</li>
                <li>Customers</li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold mb-2">RESOURCES</h3>
              <ul>
                <li>Support</li>
                <li>Blog</li>
                <li>Customer Stories</li>
                <li>Integrations</li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center">
            © Copyright 2023. All Rights Reserved.
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ColdEmailCampaignForm;