import React from 'react';
import ProductIdeaTester from './ProductIdeaTester';
import ColdEmailCampaignForm from './ColdEmailCampaignForm';
import './translations';

function App() {
  return (
    <div className="App">
      <ProductIdeaTester />
    </div>
  );
}

export default App;