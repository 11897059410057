import React, { useState } from 'react';
import OpenAI from 'openai';

const client = new OpenAI({
  apiKey: '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', // This is the default and can be omitted
  dangerouslyAllowBrowser: true,
});


const ProductIdeaTester = () => {
  const [formData, setFormData] = useState({
    product: 'Dviračių remontas',
    features: 'Mes remontuojame visų rūšių dviračius',
    problem: 'Man reikia sutaisyti dviratį',
    email: 'dviraciuremontas@dviraciupardbostone.com',
    cta: 'schedule',
    ctaOther: '',
    tone: 'friendly',
    jargon: ''
  });
  // random companies that could be interested in purchasing the bike repair service
  const [companies, setCompanies] = useState([
    { name: 'Bostono savivaldybė', contact: 'pirkimai@boston.gov' },
    { name: 'Google biuras Berlyne', contact: 'biuroreikmenys@google.de' },
    { name: 'Sporto klubas "Naktinis raitelis"', contact: 'naktinisraitelis@sportoklubas.com' },
    { name: 'Atletikos klubas "Bėgikas"', contact: 'begikas@atletikosklubas.com' },
    { name: 'Žygių klubas "Kalno vaizdas"', contact: 'zygiuklubas@kalnovaizas.com' },
  ]);
  const [emailTemplate, setEmailTemplate] = useState('');
  const [generatedEmails, setGeneratedEmails] = useState(Array(5).fill(''));
  const [modificationLevel, setModificationLevel] = useState(50);
  const [gpt4Prompts, setGpt4Prompts] = useState({
    templateGeneration: 'Sukurkite el. laiško šabloną pagal šią informaciją:',
    emailGeneration: 'Jums bus pateiktas šablonas ir įmonė gavėja. Tuomet sugeneruosite el. laišką tai įmonei pagal šabloną. Jums taip pat bus pateiktas modifikacijos lygis nuo 0 iki 100. Turite modifikuoti el. laišką, kad jis būtų labiau asmeniškas ir aktualus įmonei, bet vis dar laikytųsi šablono ir modifikacijos lygio. 0 lygis reiškia, kad el. laiškas turėtų būti identiškas šablonui, o 100 lygis reiškia, kad el. laiškas turėtų būti visiškai performuluotas nuo šablono.',
    companyGeneration: 'Sugeneruokite 5 atsitiktinių įmonių pavadinimus ir kontaktinę informaciją'
  });
  const [disableGPT4, setDisableGPT4] = useState(false);
  const [debugLog, setDebugLog] = useState([]);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCompanyChange = (index, field, value) => {
    const newCompanies = [...companies];
    newCompanies[index] = { ...newCompanies[index], [field]: value };
    setCompanies(newCompanies);
  };

  const generateEmailTemplate = async () => {
    const prompt = `${gpt4Prompts.templateGeneration}\n<formData>
  <product>${formData.product}</product>
  <features>${formData.features}</features>
  <problem>${formData.problem}</problem>
  <email>${formData.email}</email>
  <cta>${formData.cta}</cta>
  <ctaOther>${formData.ctaOther}</ctaOther>
  <tone>${formData.tone}</tone>
  <jargon>${formData.jargon}</jargon>
</formData>`;
    const result = await callGPT4API(prompt);
    setEmailTemplate(result);
    logDebug('Sugeneruotas el. laiško šablonas', prompt, result);
  };

  const generateCompanies = async () => {
    const result = await callGPT4API(gpt4Prompts.companyGeneration);
    const newCompanies = JSON.parse(result);
    setCompanies(newCompanies);
    logDebug('Sugeneruotos įmonės', gpt4Prompts.companyGeneration, result);
  };

  const generateEmails = async () => {
    const newEmails = await Promise.all(companies.map(async (company, index) => {
      const prompt = `${gpt4Prompts.emailGeneration}\nŠablonas: ${emailTemplate}\nInformacija apie įmonę gavėją: ${JSON.stringify(company)}\nModifikacijos lygis: ${modificationLevel}`;
      const result = await callGPT4API(prompt);
      logDebug(`Sugeneruotas el. laiškas įmonei ${index + 1}`, prompt, result);
      return result;
    }));
    setGeneratedEmails(newEmails);
  };

  const callGPT4API = async (prompt) => {
    if (disableGPT4) {
      return prompt;
    }
    const chatCompletion = await client.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o-mini',
    });
    logDebug('OpenAI API iškvietimo parametrai', prompt, JSON.stringify(chatCompletion));
    return chatCompletion.choices[0].message.content;
  };

  const logDebug = (action, input, output) => {
    setDebugLog(prev => [...prev, { timestamp: new Date().toISOString(), action, input, output }]);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Produkto idėjos testuotojas</h1>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Produkto informacijos forma</h2>
        <form onSubmit={(e) => { e.preventDefault(); generateEmailTemplate(); }}>
          <input
            name="product"
            value={formData.product}
            onChange={handleFormChange}
            placeholder="Koks yra pagrindinis produktas / paslauga, kurį(-ią) parduodate?"
            className="w-full p-2 mb-2 border rounded"
          />
          <textarea
            name="features"
            value={formData.features}
            onChange={handleFormChange}
            placeholder="Kokios yra pagrindinės jūsų produkto / paslaugos savybės?"
            className="w-full p-2 mb-2 border rounded"
          />
          <textarea
            name="problem"
            value={formData.problem}
            onChange={handleFormChange}
            placeholder="Kokią problemą jūsų produktas / paslauga sprendžia klientui?"
            className="w-full p-2 mb-2 border rounded"
          />
          <textarea
            name="email"
            value={formData.email}
            onChange={handleFormChange}
            placeholder="Prašome suformuluoti savo el. laišką savais žodžiais."
            className="w-full p-2 mb-2 border rounded"
          />
          <select
            name="cta"
            value={formData.cta}
            onChange={handleFormChange}
            className="w-full p-2 mb-2 border rounded"
          >
            <option value="">Pasirinkite raginimą veikti</option>
            <option value="schedule">Suplanuoti skambutį</option>
            <option value="visit">Apsilankyti jūsų svetainėje</option>
            <option value="respond">Atsakyti į jūsų el. laišką</option>
            <option value="other">Kita</option>
          </select>
          {formData.cta === 'other' && (
            <input
              name="ctaOther"
              value={formData.ctaOther}
              onChange={handleFormChange}
              placeholder="Kitas raginimas veikti"
              className="w-full p-2 mb-2 border rounded"
            />
          )}
          <select
            name="tone"
            value={formData.tone}
            onChange={handleFormChange}
            className="w-full p-2 mb-2 border rounded"
          >
            <option value="">Pasirinkite toną</option>
            <option value="formal">Formalus</option>
            <option value="friendly">Draugiškas</option>
            <option value="casual">Neformalus</option>
            <option value="friendly_emoji">Draugiškas su jaustukais</option>
          </select>
          <input
            name="jargon"
            value={formData.jargon}
            onChange={handleFormChange}
            placeholder="Pramonės žargonas ar terminologija, kurią įtraukti arba vengti"
            className="w-full p-2 mb-2 border rounded"
          />
          <button type="submit" className="w-full p-2 bg-blue-500 text-white rounded">Generuoti el. laiško šabloną</button>
        </form>
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">El. laiško šablonas</h2>
        <textarea
          value={emailTemplate}
          onChange={(e) => setEmailTemplate(e.target.value)}
          className="w-full p-2 mb-2 border rounded"
          rows={10}
        />
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Įmonės informacija</h2>
        {companies.map((company, index) => (
          <div key={index} className="mb-2">
            <input
              value={company.name}
              onChange={(e) => handleCompanyChange(index, 'name', e.target.value)}
              placeholder={`Įmonės ${index + 1} pavadinimas`}
              className="w-full p-2 mb-1 border rounded"
            />
            <input
              value={company.contact}
              onChange={(e) => handleCompanyChange(index, 'contact', e.target.value)}
              placeholder={`Įmonės ${index + 1} kontaktai`}
              className="w-full p-2 mb-1 border rounded"
            />
          </div>
        ))}
        <button onClick={generateCompanies} className="w-full p-2 bg-green-500 text-white rounded">Generuoti atsitiktines įmones</button>
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Sugeneruoti el. laiškai</h2>
        <input
          type="range"
          value={modificationLevel}
          onChange={(e) => setModificationLevel(e.target.value)}
          min={0}
          max={100}
          step={1}
          className="w-full mb-2"
        />
        <p>Modifikacijos lygis: {modificationLevel}%</p>
        <button onClick={generateEmails} className="w-full p-2 mb-2 bg-purple-500 text-white rounded">Generuoti el. laiškus</button>
        {generatedEmails.map((email, index) => (
          <textarea
            key={index}
            value={email}
            onChange={(e) => {
              const newEmails = [...generatedEmails];
              newEmails[index] = e.target.value;
              setGeneratedEmails(newEmails);
            }}
            className="w-full p-2 mb-2 border rounded"
            rows={5}
          />
        ))}
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">GPT-4 užklausos</h2>
        {Object.entries(gpt4Prompts).map(([key, value]) => (
          <div key={key} className="mb-2">
            <label className="block mb-1">{key}</label>
            <textarea
              value={value}
              onChange={(e) => setGpt4Prompts({ ...gpt4Prompts, [key]: e.target.value })}
              className="w-full p-2 border rounded"
              rows={3}
            />
          </div>
        ))}
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-bold mb-2">Derinimo nustatymai</h2>
        <label className="flex items-center mb-2">
          <input
            type="checkbox"
            checked={disableGPT4}
            onChange={(e) => setDisableGPT4(e.target.checked)}
            className="mr-2"
          />
          Išjungti GPT-4 API iškvietimus
        </label>
      </div>

      <div>
        <h2 className="text-xl font-bold mb-2">Derinimo žurnalas</h2>
        <pre className="whitespace-pre-wrap border p-2 rounded">
          {JSON.stringify(debugLog, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default ProductIdeaTester;
