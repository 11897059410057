import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      productIdeaTester: 'Product Idea Tester',
      switchLanguage: 'Switch to Lithuanian',
      productInformationForm: 'Product Information Form',
      productPlaceholder: 'What is the main product / service you are selling?',
      featuresPlaceholder: 'What are the key features of your product / service?',
      problemPlaceholder: 'What problem does your product / service solve for the client?',
      emailPlaceholder: 'Please formulate your email in your own words.',
      selectCTA: 'Select CTA',
      scheduleCall: 'Schedule a call',
      visitWebsite: 'Visit your website',
      respondEmail: 'Respond to your email',
      other: 'Other',
      otherCTA: 'Other CTA',
      selectTone: 'Select tone',
      formal: 'Formal',
      friendly: 'Friendly',
      casual: 'Casual',
      friendlyEmoji: 'Friendly with emojis',
      jargonPlaceholder: 'Industry jargon or terminology to include or avoid',
      generateEmailTemplate: 'Generate Email Template',
      emailTemplate: 'Email Template',
      companyInformation: 'Company Information',
      companyNamePlaceholder: 'Company {{number}} Name',
      companyContactPlaceholder: 'Company {{number}} Contact',
      generateRandomCompanies: 'Generate Random Companies',
      generatedEmails: 'Generated Emails',
      modificationLevel: 'Modification Level: {{level}}%',
      generateEmails: 'Generate Emails',
      gpt4Prompts: 'GPT-4 Prompts',
      templateGeneration: 'Template Generation',
      emailGeneration: 'Email Generation',
      companyGeneration: 'Company Generation',
      debugSettings: 'Debug Settings',
      disableGPT4Calls: 'Disable GPT-4 API Calls',
      debugLog: 'Debug Log',
      bikeShopProduct: 'High-quality bicycles and accessories',
      bikeShopFeatures: 'Wide range of bikes for all skill levels, expert fitting services, lifetime maintenance',
      bikeShopProblem: 'Finding the right bike and maintaining it properly for optimal performance and enjoyment',
      bikeShopEmail: 'We offer a wide selection of top-quality bikes and accessories, along with expert fitting and maintenance services to ensure you get the most out of your cycling experience.',
      bikeShopJargon: 'Groupset, drivetrain, carbon fiber, tubeless, suspension'
    }
  },
  lt: {
    translation: {
      productIdeaTester: 'Produkto idėjos testuotojas',
      switchLanguage: 'Perjungti į anglų kalbą',
      productInformationForm: 'Produkto informacijos forma',
      productPlaceholder: 'Koks yra pagrindinis produktas / paslauga, kurią parduodate?',
      featuresPlaceholder: 'Kokios yra pagrindinės jūsų produkto / paslaugos savybės?',
      problemPlaceholder: 'Kokią problemą jūsų produktas / paslauga sprendžia klientui?',
      emailPlaceholder: 'Prašome suformuluoti savo el. laišką savais žodžiais.',
      selectCTA: 'Pasirinkite CTA',
      scheduleCall: 'Suplanuoti skambutį',
      visitWebsite: 'Apsilankyti jūsų svetainėje',
      respondEmail: 'Atsakyti į jūsų el. laišką',
      other: 'Kita',
      otherCTA: 'Kitas CTA',
      selectTone: 'Pasirinkite toną',
      formal: 'Formalus',
      friendly: 'Draugiškas',
      casual: 'Kasdienis',
      friendlyEmoji: 'Draugiškas su emotikonais',
      jargonPlaceholder: 'Pramonės žargonas ar terminologija, kurią reikia įtraukti arba vengti',
      generateEmailTemplate: 'Generuoti el. pašto šabloną',
      emailTemplate: 'El. pašto šablonas',
      companyInformation: 'Įmonės informacija',
      companyNamePlaceholder: 'Įmonės {{number}} pavadinimas',
      companyContactPlaceholder: 'Įmonės {{number}} kontaktas',
      generateRandomCompanies: 'Generuoti atsitiktines įmones',
      generatedEmails: 'Sugeneruoti el. laiškai',
      modificationLevel: 'Modifikacijos lygis: {{level}}%',
      generateEmails: 'Generuoti el. laiškus',
      gpt4Prompts: 'GPT-4 užklausos',
      templateGeneration: 'Šablono generavimas',
      emailGeneration: 'El. laiško generavimas',
      companyGeneration: 'Įmonės generavimas',
      debugSettings: 'Derinimo nustatymai',
      disableGPT4Calls: 'Išjungti GPT-4 API iškvietimus',
      debugLog: 'Derinimo žurnalas',
      bikeShopProduct: 'Aukštos kokybės dviračiai ir aksesuarai',
      bikeShopFeatures: 'Platus dviračių asortimentas visų lygių vairuotojams, ekspertų pritaikymo paslaugos, neribotas aptarnavimas',
      bikeShopProblem: 'Tinkamo dviračio radimas ir tinkamas jo priežiūra optimaliam veikimui ir malonumui',
      bikeShopEmail: 'Mes siūlome platų aukštos kokybės dviračių ir aksesuarų asortimentą, taip pat ekspertų pritaikymo ir priežiūros paslaugas, kad galėtumėte gauti maksimalią naudą iš dviračių sporto patirties.',
      bikeShopJargon: 'Pavarų komplektas, pavara, anglies pluoštas, bekameris, amortizacija'
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;